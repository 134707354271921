import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { Context } from "../../Context/Context";
import { SiGooglesheets } from "react-icons/si";
import { Tooltip } from "reactstrap";
import { useParams } from "react-router-dom";
import Loading from "../Loading";
import ScheduleService from "../../Services/ScheduleService";

const SheetsIntegration = ({ handleOpenDetails }) => {
  const { userInformation, setSheetLink } = useContext(Context);
  const [loading, setLoading] = useState(true);
  let { projectId } = useParams();
  const [inputText, setInputText] = useState("");
  const [tooltip, setTooltip] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState("");

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleIntegrationLink = async () => {
    if (inputText === "") {
      setTooltipMessage("Informe um link de Google Sheets válido");
      return setTooltip(true);
    } else {
      let sheetSplited = inputText.split("/");
      let sheetId = sheetSplited[5];
      let response = await ScheduleService.createSchedule(
        JSON.stringify({
          user: userInformation.email,
          campaign: projectId,
          id_sheet: sheetId,
        }),
      );
      if (response.data === "Id sheet was registered") {
        setSheetLink(inputText);
        handleOpenDetails();
      } else {
        setTooltipMessage("Ocorreu um erro durante a integração");
        setTooltip(true);
      }
    }
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="sheets-integration-page">
        <div className="integration-header">
          <div className="integration-elements">
            <div>
              <SiGooglesheets color="green" size="50px" />
            </div>
            <div className="integration-texts">
              <h3>Google Sheets</h3>
              <p>Sincronize suas respostas com o Planilhas Google.</p>
            </div>
          </div>
        </div>
        <div className="input-container">
          <Tooltip
            innerClassName="tooltip-erro"
            placement="top"
            isOpen={tooltip}
            target="sheetLink"
          >
            {tooltipMessage}
          </Tooltip>
          <p>Link da sua planilha:</p>
          <input
            id="sheetLink"
            className="input-box"
            onChange={(event) => {
              setInputText(event.target.value);
              setTooltip(false);
            }}
            placeholder="Digite aqui o link da sua planilha"
          />
          <p className="subtitle-texts">
            <b>Importante: </b>
            Informe o link da planilha com permissão de edição para todos.
          </p>
          <button className="onlyUnderline" onClick={handleOpenDetails}>
            Clique aqui para ver o tutorial
          </button>
        </div>
        <div className="message-box">
          <p>
            A sincronização dos dados com a planilha Google será realizada a
            cada 24 horas.
          </p>
        </div>

        <div className="buttons">
          <button
            className="button-save"
            onClick={() => handleIntegrationLink()}
          >
            Salvar
          </button>
          <button className="onlyUnderline" onClick={handleOpenDetails}>
            Cancelar
          </button>
        </div>
      </div>
    );
  }
};

export default SheetsIntegration;
