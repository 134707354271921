import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../Context/Context";

import HeaderProjectsPage from "../../Components/HeaderProjectsPage";

import Hazo from "../../Assets/img/hazo_messages.png";
import Close from "../../Assets/img/carbon_close.png";
import SaveOn from "../../Assets/img/saveOn.png";
import SaveOff from "../../Assets/img/saveOff.png";

import "./style.scss";
import MessagesService from "../../Services/MessagesService";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

const Messages = () => {
  const history = useHistory();
  const {
    userMessages,
    setUserMessages,
    qtdNewMessages,
    getQtdNewMessages,
    userInformation,
  } = useContext(Context);
  const [activeTab, setActiveTab] = useState("todas");

  const byDate = (a, b) => {
    return new Date(a.created_at) - new Date(b.created_at);
  };

  const saveMessage = async (idMessage) => {
    const messageIndex = userMessages.findIndex(
      (message) => message.id === idMessage,
    );

    if (messageIndex !== -1) {
      const { is_saved, id, ...message } = userMessages[messageIndex];
      const updatedIsSaved = parseInt(is_saved) ? 0 : 1;
      const updatedMessage = {
        is_saved: updatedIsSaved,
        id,
        ...message,
      };

      const updatedMessages = [...userMessages];
      updatedMessages[messageIndex] = updatedMessage;

      setUserMessages(updatedMessages);
      await MessagesService.saveMessage(idMessage, updatedIsSaved);
    }
  };

  const handleLink = (url) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  const handleAllMessages = () => {
    const dataToReturn = [];
    const messages =
      activeTab === "todas"
        ? userMessages
        : userMessages.filter((message) => parseInt(message.is_saved));

    if (!messages.length) {
      dataToReturn.push(
        <p key={0} className="warning">
          Ops! Você ainda não tem mensagens salvas
        </p>,
      );
    } else {
      messages
        .sort(byDate)
        .reverse()
        .map((message, index) => {
          const day = moment(message.created_at).format("DD");
          const month = moment(message.created_at).format("MMM");
          const hour = moment(message.created_at).format("HH:mm");
          const dateHourFormat = `${day}/${month} - ${hour}`;

          return dataToReturn.push(
            <div
              className={
                parseInt(message.is_new_message)
                  ? "messages messagesOn"
                  : "messages messagesOff"
              }
              key={message.id}
            >
              <button
                className="buttonSave"
                onClick={() => saveMessage(message.id, index)}
              >
                <img
                  src={parseInt(message.is_saved) ? SaveOn : SaveOff}
                  alt=""
                />
              </button>
              <p className="title">{message.title}</p>
              <p className="description">{message.description}</p>
              {message.link && (
                <button
                  className="link"
                  onClick={() => handleLink(message.link)}
                >
                  {message.link_name}
                </button>
              )}
              <span className="dateInfo">{dateHourFormat}</span>
              <div className="line"></div>
            </div>,
          );
        });
    }
    return dataToReturn;
  };

  const handleClosePage = async () => {
    let data = await MessagesService.readAllMessages();

    if (data.response === "success") {
      let data = await MessagesService.getMessagesByUser();
      if (data.response === "success") {
        setUserMessages(data.messages);
        getQtdNewMessages(data.messages);
      }
    }
    history.push("/projects");
  };

  return (
    <div className="pageMessages">
      <HeaderProjectsPage name={userInformation ? userInformation.name : ""} />
      <div className="contentArea">
        <div className="headerMessages">
          <button className="close" onClick={() => handleClosePage()}>
            <img src={Close} alt="" />
          </button>
          <div className="contentTitle">
            <img src={Hazo} alt="Hazo" />
            <span className="title">Mensagens</span>
          </div>
          <div className="menu">
            <div
              className={activeTab === "todas" ? "containerOn" : "containerOff"}
            >
              <button
                className={activeTab === "todas" ? "" : "buttonOff"}
                onClick={() => setActiveTab("todas")}
              >
                Todas
              </button>
              {qtdNewMessages > 0 && <div id="badge">{qtdNewMessages}</div>}
            </div>
            <div
              className={
                activeTab === "salvas" ? "containerOn" : "containerOff"
              }
            >
              <button
                className={activeTab === "salvas" ? "" : "buttonOff"}
                onClick={() => setActiveTab("salvas")}
              >
                Salvas
              </button>
            </div>
          </div>
          <div className="line"></div>
        </div>
        {userMessages && userMessages.length > 0 ? (
          <div className="containerMessages">{handleAllMessages()}</div>
        ) : (
          <div className="containerMessages">
            <p className="warning">Ops! Você não tem mensagens no momento</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;
