import React, { useContext, useState } from "react";
import "./style.scss";
import { Context } from "../../Context/Context";
import { Tooltip } from "reactstrap";
import { useHistory } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import HazoError from "../../Assets/img/256PX-02.png";
import Loading from "../../Components/Loading";
import ModalWarning from "../../Components/Modals/ModalWarning";
import PaypalService from "../../Services/PaypalService";

const CancelPlan = () => {
  const history = useHistory();
  const { updateUserInformation, userInformation } = useContext(Context);
  const [modalWarning, setModalWarning] = useState(false);
  const [modalWarningMessage, setModalWarningMessage] = useState("");
  const [reason, setReason] = useState("");
  const [reasonMessage, setReasonMessage] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCancelPlan = async () => {
    if (reason === "") {
      setTooltipOpen(true);
    } else {
      setLoading(true);
      setTooltipOpen(false);

      let reasonToRequest =
        reason !== "Outro (Explique o motivo abaixo)" ? reason : reasonMessage;
      let response = await PaypalService.cancelSubscription(
        userInformation.id_subscription_paypal,
        reasonToRequest,
      );
      await updateUserInformation();
      setLoading(false);

      if (response === "") {
        history.push("/projects");
      } else {
        setModalWarningMessage(
          <>
            <img src={HazoError} alt="camaleão-hazo" />
            <h3>Lamento aconteceu um problema</h3>
            <button onClick={() => history.push("/projects")}>Ok</button>
          </>,
        );
        setModalWarning(true);
      }
    }
  };

  const handleReason = (reasonValue) => {
    if (reasonValue.length < 128) {
      setReasonMessage(reasonValue);
    }
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="compCancelPlan">
        <div className="content">
          <div className="icon">
            <button className="btn-icon">
              <FaArrowLeft onClick={() => history.goBack()} />
            </button>
          </div>
          <ModalWarning modal={modalWarning} setModal={setModalWarning}>
            {modalWarningMessage}
          </ModalWarning>
          <img src={HazoError} alt="camaleão-hazo" />
          <p>
            Sentimos muito em saber que você deseja cancelar seu plano. Antes de
            continuar, conte pra gente o motivo da sua escolha:
          </p>
          <Tooltip
            innerClassName="redBackground"
            className="redBackground"
            placement="top"
            isOpen={tooltipOpen}
            target={"reason"}
          >
            Escolha um motivo
          </Tooltip>
          <div className="reasonArea" id="reason">
            <div className="inputArea">
              <input
                checked={reason === "Problemas técnicos" ? true : false}
                id="problemas_tecnicos"
                onChange={(event) => setReason(event.target.value)}
                type="radio"
                value="Problemas técnicos"
              />
              <label htmlFor="problemas_tecnicos">Problemas técnicos</label>
            </div>
            <div className="inputArea">
              <input
                checked={reason === "Valores altos" ? true : false}
                id="valores_altos"
                onChange={(event) => setReason(event.target.value)}
                type="radio"
                value="Valores altos"
              />
              <label htmlFor="valores_altos">Valores altos</label>
            </div>
            <div className="inputArea">
              <input
                id="mudanca_para_outro_produto"
                checked={reason === "Mudança para outro produto" ? true : false}
                onChange={(event) => setReason(event.target.value)}
                type="radio"
                value="Mudança para outro produto"
              />
              <label htmlFor="mudanca_para_outro_produto">
                Mudança para outro produto
              </label>
            </div>
            <div className="inputArea">
              <input
                id="dificil_de_usar"
                checked={reason === "Difícil de usar" ? true : false}
                onChange={(event) => setReason(event.target.value)}
                type="radio"
                value="Difícil de usar"
              />
              <label htmlFor="dificil_de_usar">Difícil de usar</label>
            </div>
            <div className="inputArea">
              <input
                id="ferramentas_necessáris_indisponiveis"
                checked={
                  reason === "Ferramentas necessáris indisponíveis"
                    ? true
                    : false
                }
                onChange={(event) => setReason(event.target.value)}
                type="radio"
                value="Ferramentas necessáris indisponíveis"
              />
              <label htmlFor="ferramentas_necessáris_indisponiveis">
                Ferramentas necessáris indisponíveis
              </label>
            </div>
            <div className="inputArea">
              <input
                id="outro"
                checked={
                  reason === "Outro (Explique o motivo abaixo)" ? true : false
                }
                onChange={(event) => setReason(event.target.value)}
                type="radio"
                value="Outro (Explique o motivo abaixo)"
              />
              <label htmlFor="outro">Outro (Explique o motivo abaixo)</label>
            </div>
            <textarea
              rows={4}
              onChange={(evt) => handleReason(evt.target.value)}
              onClick={() => setReason("Outro (Explique o motivo abaixo)")}
              value={reasonMessage}
              placeholder="Algo que você queira compartilhar?"
            />
          </div>
          <button className="onlyUnderline" onClick={() => handleCancelPlan()}>
            Cancelar Plano
          </button>
        </div>
      </div>
    );
  }
};

export default CancelPlan;
