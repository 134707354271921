import React from "react";
import "./style.scss";
import "../style.scss";
import Change from "../../../Assets/img/change.png";
import InputColor from "../InputColor";

const MenuInteractive = (props) => {
  const handleUrlValue = (optionNumber, url) => {
    let arrayTemp = [...props.questionValues.questions];
    let arrayWithNewColor = [...arrayTemp[props.questionNumber].options];
    arrayWithNewColor[optionNumber] = {
      ...arrayWithNewColor[optionNumber],
      url: url,
    };
    arrayTemp[props.questionNumber] = {
      ...arrayTemp[props.questionNumber],
      options: arrayWithNewColor,
    };
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  const handleTypeOfOption = (optionNumber, type) => {
    let arrayTemp = [...props.questionValues.questions];
    let arrayWithNewType = [...arrayTemp[props.questionNumber].options];
    arrayWithNewType[optionNumber] = {
      ...arrayWithNewType[optionNumber],
      type: type,
    };
    arrayTemp[props.questionNumber] = {
      ...arrayTemp[props.questionNumber],
      options: arrayWithNewType,
    };
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  const handleAddOption = (questionNumber) => {
    let arrayTemp = [...props.questionValues.questions];
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      options: [
        ...arrayTemp[questionNumber].options,
        { color: "#606c88", next: 0, value: "", url: "", type: "link" },
      ],
    };
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  const handleClickInput = (element) => {
    document.getElementById(element).click();
  };

  return (
    <div className="compMenuInteractive">
      {props.questionValues.questions[props.questionNumber].options.map(
        (option, index) => {
          return (
            <div className="lineOption" key={index}>
              <div style={{ marginBottom: "10px" }}>Opção {index + 1}:</div>
              <div className="optionsMenuInteractive">
                <button
                  className={
                    props.questionValues.questions[props.questionNumber]
                      .options[index].type === "link"
                      ? "buttonSelectedType"
                      : "buttonNotSelectedType"
                  }
                  onClick={() => handleTypeOfOption(index, "link")}
                >
                  Link
                </button>
                <img alt="Change" className="iconChange" src={Change} />
                <button
                  className={
                    props.questionValues.questions[props.questionNumber]
                      .options[index].type === "interactive"
                      ? "buttonSelectedType"
                      : "buttonNotSelectedType"
                  }
                  onClick={() => handleTypeOfOption(index, "interactive")}
                >
                  Botão Interativo
                </button>
              </div>
              <div className="optionsMenuInteractive gap">
                <InputColor
                  option={option}
                  index={index}
                  questionNumber={props.questionNumber}
                  handleColor={props.handleColor}
                  setTooltipOpen={() => {}}
                  handleOptionValue={props.handleOptionValue}
                  handleClickInput={handleClickInput}
                  handleKeyDown={props.handleKeyDown}
                  handleRemoveOption={props.handleRemoveOption}
                  moveDownOption={props.moveDownOption}
                  moveUpOption={props.moveUpOption}
                />
              </div>
              {props.questionValues.questions[props.questionNumber].options[
                index
              ].type === "link" ? (
                <input
                  className="form-control"
                  value={
                    props.questionValues.questions[props.questionNumber]
                      .options[index].url
                  }
                  placeholder="Inserir Link (URL)"
                  onChange={(event) =>
                    handleUrlValue(index, event.target.value)
                  }
                />
              ) : null}
            </div>
          );
        },
      )}
      <button
        className="buttonAddOption"
        onClick={() => handleAddOption(props.questionNumber)}
      >
        Adicionar Opção
      </button>
    </div>
  );
};
export default MenuInteractive;
