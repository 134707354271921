import React from "react";
import "./style.scss";

const InsertImage = (props) => {
  const handleImageUrl = (url) => {
    let linkIsGoogleDrive = url.includes("drive.google.com");

    const imageURL = linkIsGoogleDrive
      ? `https://docs.google.com/uc?id=${url.split("/d/")[1].split("/")[0]}`
      : url;

    let arrayTemp = [...props.questionValues.questions];
    arrayTemp[props.questionNumber] = {
      ...arrayTemp[props.questionNumber],
      options: imageURL,
      error: false,
    };
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  return (
    <div className="compInsertImage">
      <input
        className="form-control inputUrl"
        value={props.questionValues.questions[props.questionNumber].options}
        placeholder="Cole aqui URL da imagem"
        onChange={(event) => {
          handleImageUrl(event.target.value);
        }}
      />
      <p className="warning">
        Atenção: ao adicionar um link de imagem do Google Drive, lembre-se de
        configurar o compartilhamento como público.
      </p>
    </div>
  );
};

export default InsertImage;
