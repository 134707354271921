import React, { useState, useRef } from "react";
import "./style.scss";
import { Tooltip } from "reactstrap";
import { useHistory } from "react-router-dom";
import AuthService from "../../Services/AuthService";
import EyeInvisible from "../../Assets/img/ICONEOLHOHAZOFECHADO.png";
import EyeVisible from "../../Assets/img/ICONEOLHOHAZO.png";
import Hazo from "../../Assets/img/256PX-01.png";
import ReCAPTCHA from "react-google-recaptcha";

const SignIn = () => {
  const captchaRef = useRef();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [tooltipEmail, setTooltipEmail] = useState(false);
  const [emailTooltipMessage, setEmailTooltipMessage] = useState("");
  const [tooltipPassword, setTooltipPassword] = useState(false);
  const [passwordTooltipMessage, setPasswordTooltipMessage] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);

  const handleLogin = async () => {
    let loginValues = {
      emailLogin: email,
      passwordLogin: password,
    };

    if (
      !loginValues.emailLogin.match(
        // eslint-disable-next-line
        /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
      )
    ) {
      setEmailTooltipMessage("Formato do email inválido");
      return setTooltipEmail(true);
    }

    if (loginValues.emailLogin === "") {
      setEmailTooltipMessage("Campo do email é obrigatório");
      return setTooltipEmail(true);
    }

    if (loginValues.passwordLogin.length < 6) {
      setPasswordTooltipMessage(
        "Senha muito pequena, digite pelo menos 6 caracteres",
      );
      return setTooltipPassword(true);
    }

    if (loginValues.passwordLogin === "") {
      setPasswordTooltipMessage("Campo de senha é obrigatorio");
      return setTooltipPassword(true);
    }

    const token = captchaRef.current.getValue();

    if (!token) {
      setCaptchaError(true);
      return;
    } else {
      const response = await AuthService.verifyRecaptcha(token);

      if (response.status === 200) {
        await AuthService.signIn(
          loginValues.emailLogin,
          loginValues.passwordLogin,
        )
          .then(() => {
            history.push("/projects");
            captchaRef.current.reset();
          })
          .catch((err) => {
            const errorCode = err.code;
            let errorMessage = verifyErroCode(errorCode);

            if (errorMessage == null) {
              errorMessage = err.message;
            }

            setLoginError(true);
            setLoginErrorMessage(errorMessage);
          });
      }
    }
  };

  const verifyErroCode = (error) => {
    switch (error) {
      case "auth/user-disabled":
        return "Essa conta foi removida.";
      case "auth/user-not-found":
        return "O usuário informado não possui cadastro.";
      case "auth/email-already-in-use":
        return "Já existe uma conta com o endereço de email fornecido.";
      case "auth/invalid-email":
        return "O endereço de e-mail não é válido.";
      case "auth/account-exists-with-different-credential":
        return "E-mail já esta cadastrado em outra conta.";
      case "auth/wrong-password":
        return "Senha incorreta.";
      case "auth/invalid-email-verified":
        return "O e-mail é inválido.";
      default:
        return null;
    }
  };

  const handleEnter = (e) => {
    if (e === "Enter") return handleLogin();
  };

  return (
    <div className="CompSignIn">
      <div className="content">
        <div className="textArea">
          Que bom ver você de novo!
          <br />
          Digita para mim os seus dados
          <br />
          para entrar na sua conta!
        </div>
        <div id="triangulo-para-baixo"></div>
        <img alt="logo hazo" className="hazoImage" src={Hazo} />
        <div className="hazotext">Hazo</div>
        <div className="title">Login</div>
        <div className="inputArea">
          <Tooltip placement="top" isOpen={tooltipEmail} target="email">
            {emailTooltipMessage}
          </Tooltip>
          <label htmlFor="E-mail">E-mail</label>
          <input
            id="email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
              setTooltipEmail(false);
            }}
            onKeyDown={(e) => handleEnter(e.key)}
            type="text"
          />
        </div>
        <div className="inputArea">
          <Tooltip placement="top" isOpen={tooltipPassword} target="password">
            {passwordTooltipMessage}
          </Tooltip>
          <label htmlFor="Senha">Senha</label>
          <div>
            <input
              id="password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
                setTooltipPassword(false);
              }}
              onKeyDown={(e) => handleEnter(e.key)}
              type={passwordVisible ? "text" : "password"}
            />
            <img
              alt={passwordVisible ? "olho aberto" : "olho olho fechado"}
              onClick={() => setPasswordVisible(!passwordVisible)}
              src={passwordVisible ? EyeInvisible : EyeVisible}
            />
          </div>
        </div>
        <div className="recaptcha">
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            className={captchaError ? "recaptchaError" : null}
            onChange={() => setCaptchaError(false)}
          />
        </div>

        {captchaError ? (
          <div className="errorMessageCaptcha">
            Por favor, resolva o captcha primeiro!
          </div>
        ) : null}

        <div className="buttonSignin buttonHover" onClick={() => handleLogin()}>
          Login
        </div>

        {loginError ? (
          <div className="errorMessage">{loginErrorMessage}</div>
        ) : null}

        <div
          className="buttonRedirect"
          onClick={() => history.push("forgotpassword")}
        >
          <span>
            Esqueceu a Senha? <u>Clique Aqui</u>
          </span>
        </div>
        <div
          className="buttonRedirect buttonRedirectLarge"
          onClick={() => history.push("signup")}
        >
          Criar uma nova conta
        </div>
      </div>
    </div>
  );
};

export default SignIn;
