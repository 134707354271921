import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from "react";
import "./style.scss";
import { Context } from "../../Context/Context";
import { useParams } from "react-router-dom";
import ButtonOnOff from "../../Components/ButtonOnOff";
import Calendar from "../../Assets/img/calendar.png";
import ChartsAndTables from "../../Components/ChartsAndTables";
import Hazo from "../../Assets/img/256PX-01-whiout-padding.svg";
import HeaderResults from "../../Components/HeaderResults";
import Loading from "../../Components/Loading";
import ModalExport from "../../Components/Modals/ModalExport";
import MenuReports from "../../Components/MenuReports";
import NavigationHeader from "../../Components/NavigationHeader";
import ProjectsService from "../../Services/ProjectsService";
import ReportData from "../../Components/ReportData";
import { useReactToPrint } from "react-to-print";
import XLSX from "sheetjs-style";

const Reports = () => {
  let { projectId } = useParams();
  const {
    chatbot,
    handleTotalInteractions,
    userInformation,
    dataTableXlsx,
    getNumberQuestionInDictionary,
    setLoadingEngagement,
  } = useContext(Context);
  const [answerAvgTime, setAnswerAvgTime] = useState(0);
  const [chatbotTemp] = useState(chatbot);
  const [dataFirebase, setDataFirebase] = useState(null);
  const [engagementData, setEngagementData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalExport, setModalExport] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [searchStatus, setSearchStatus] = useState(null);
  const [showTables, setShowTables] = useState({});
  const [tableDatabaseToExcel, setTableDatabaseToExcel] = useState(null);
  const [typeOfChart, setTypeOfChart] = useState({
    answers: "LineChart",
  });
  const [dateMin, setDateMin] = useState("");
  const [dateMax, setDateMax] = useState("");
  const [tabNavigation, setTabNavigation] = useState("report");
  const [selectedQuestion, setSelectedQuestion] = useState("");

  useEffect(() => {
    const getFilterQuestion = async () => {
      const question = await ProjectsService.getFilterQuestion(projectId);

      if (question) {
        setSelectedQuestion(question);
      }
    };

    getFilterQuestion();
    // eslint-disable-next-line
  }, []);

  const pdfRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month =
      Math.abs(date.getMonth() + 1).toString().length === 1
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month}-${day}`;
  };

  const currentDate = getCurrentDate();

  useEffect(() => {
    const getProjectIsOpen = async () => {
      let response = await ProjectsService.getClosedProject(
        projectId,
        userInformation.email,
        userInformation.subscription,
      );
      if (response !== null) {
        if (response.closed === 1) {
          setSearchStatus(false);
        } else {
          setSearchStatus(true);
        }
      }
    };

    if (userInformation !== null && searchStatus === null) {
      getProjectIsOpen();
    }
    // eslint-disable-next-line
  }, [userInformation]);

  useEffect(() => {
    const mountShowTables = (dataFirebaseTmp) => {
      let objectShowTable = showTables;
      for (let i = 0; i < dataFirebaseTmp.questions.length; i++) {
        objectShowTable = {
          ...objectShowTable,
          [dataFirebaseTmp.questions[i].number]: false,
        };
      }
      setShowTables(objectShowTable);
    };

    const getFirebaseData = async () => {
      let dataFirebaseTmp = await ProjectsService.getSpecificProject(projectId);
      let dataFirebaseTmpParsed = JSON.parse(dataFirebaseTmp[0].json);
      dataFirebaseTmpParsed.project_name = dataFirebaseTmp[0].name;
      setDataFirebase(dataFirebaseTmpParsed);
      return dataFirebaseTmpParsed;
    };

    const getReportDatas = async (dataFirebaseTmp) => {
      let qtdAnswers = handleTotalInteractions();
      let arrayMultiple = dataFirebaseTmp.questions
        .filter((element) => {
          if (element.type === "multipleChoice") {
            return element;
          } else {
            return null;
          }
        })
        .map((element) => {
          return element.number < 10
            ? `q_0${element.number}`
            : `q_${element.number}`;
        });

      let data = await ProjectsService.getReportData(
        JSON.stringify({
          project: projectId,
          numberQuestions: dataFirebaseTmp.questions.length,
          multiple: arrayMultiple,
          qtdAnswers: typeof qtdAnswers === "number" ? qtdAnswers : 1000000,
          user: userInformation.email,
        }),
      );
      setReportData({ intro: dataFirebaseTmp.disclaimer_initial, ...data });
    };

    const getAvgTime = async (dataFirebaseTmp) => {
      if (dataFirebaseTmp.questions.length === 0) {
        return;
      }

      let lastQuestion =
        dataFirebaseTmp.questions[dataFirebaseTmp.questions.length - 1].number;
      let avgAnswer = await ProjectsService.getAvgTimeAnswer(
        projectId,
        userInformation.email,
        lastQuestion < 10 ? `q_0${lastQuestion}` : `q_${lastQuestion}`,
      );
      setAnswerAvgTime(avgAnswer.avg);
    };

    const getEngagementData = async () => {
      const qtdAnswers = handleTotalInteractions();
      const dataToSend = JSON.stringify({
        userEmail: userInformation.email,
        campaign: projectId,
        qtdAnswers: typeof qtdAnswers === "number" ? qtdAnswers : 1000000,
      });
      const data = await ProjectsService.getEngagement(dataToSend);
      setEngagementData(data);
    };

    const main = async () => {
      if (
        userInformation.projects.indexOf(projectId) > -1 ||
        userInformation.subscription === "IdssManager"
      ) {
        let dataFirebaseTmp = await getFirebaseData();
        mountShowTables(dataFirebaseTmp);
        await getReportDatas(dataFirebaseTmp);
        await getAvgTime(dataFirebaseTmp);
        await getEngagementData();
      }
      setLoading(false);
    };

    if (userInformation) {
      main();
    }
    // eslint-disable-next-line
  }, [userInformation]);

  // When render of page was complete, scroll to top page
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeKeysToEmojis = (element) => {
    switch (element) {
      case "emoji-5":
        return "😀 (5)";
      case "emoji-4":
        return "🙂 (4)";
      case "emoji-3":
        return "😐 (3)";
      case "emoji-2":
        return "🙁 (2)";
      case "emoji-1":
        return "😠 (1)";
      case "star-5":
        return "⭐⭐⭐⭐⭐ (5)";
      case "star-4":
        return "⭐⭐⭐⭐ (4)";
      case "star-3":
        return "⭐⭐⭐ (3)";
      case "star-2":
        return "⭐⭐ (2)";
      case "star-1":
        return "⭐ (1)";
      default:
        return element;
    }
  };

  const clearDates = async () => {
    if (dateMin.length || dateMax.length) {
      setDateMin("");
      setDateMax("");
      await getDataWithDate(false);
    }
  };

  const createXlsxDatabase = async () => {
    const fileName = dataFirebase?.project_name;
    let table = "";
    await getDatabaseToXlsx().then(() => {
      table = document.getElementById("tableDatabase");
    });
    const workbook = XLSX.utils.table_to_book(table, { raw: true });

    workbook.SheetNames.forEach(sheetName => {
      const worksheet = workbook.Sheets[sheetName];

      Object.keys(worksheet).forEach(cell => {
        const cellColumn = cell.replace(/\d/g, '');
        const rowIndex = cell.replace(/\D/g, '');

         if (cellColumn === 'A' && rowIndex !== "1") {
          worksheet[cell].z = '@';
        }
      });
    });
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
    setModalExport(!modalExport);
    setTableDatabaseToExcel(null);
  };

  const createXlsxTable = async () => {
    const fileName = dataFirebase?.project_name;
    const table = document.getElementById("tableConsolidated");
    const workbook = XLSX.utils.table_to_book(table);
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
    setModalExport(!modalExport);
  };

  const getDatabaseToXlsx = async () => {
    let qtdAnswers = handleTotalInteractions();
    let dataToXls = await ProjectsService.getDatabaseToXls(
      JSON.stringify({
        project: projectId,
        numberQuestions: dataFirebase.questions.length,
        qtdAnswers: typeof qtdAnswers === "number" ? qtdAnswers : 1000000,
        user: userInformation.email,
        idssManager:
          userInformation.subscription === "IdssManager" ? true : false,
      }),
    );
    let counterQuestion = 0;
    let dataBody = [];
    let dataToTable = [];
    let row = [];

    for (const key in dataToXls[0]) {
      if (dataToXls[0][key].substring(0, 2) === "q_") {
        let removeQuebra = dataFirebase.questions[
          counterQuestion
        ].message.replace("__QUEBRA__", " ");
        row.push(
          <th key={key}>
            {dataToXls[0][key]} - {removeQuebra}
          </th>,
        );
        counterQuestion++;
      } else {
        row.push(<th key={key}>{dataToXls[0][key]}</th>);
      }
    }
    dataToTable.push([
      <thead key="titleDatabase">
        <tr>{row}</tr>
      </thead>,
    ]);

    for (let i = 1; i < dataToXls.length; i++) {
      let row = [];
      dataToXls[0].forEach((value, index) => {
        if (value !== "controlador" && value !== "abertura_data") {
          value = getQuestionNumber(index - 2);
        }

        if (dataToXls[i][value] !== undefined) {
          row.push(
            <td key={`${value}-${index}`}>
              {changeKeysToEmojis(dataToXls[i][value])}
            </td>,
          );
        } else {
          row.push(<td key={`${value}-${index}`}></td>);
        }
      });
      dataBody.push(<tr key={dataToXls[i]["controlador"]}>{row}</tr>);
    }
    dataToTable.push(<tbody key="bodyDatabase">{dataBody}</tbody>);
    setTableDatabaseToExcel(dataToTable);
    return dataToTable;
  };

  const getDataWithDate = async (dates = true) => {
    setLoadingEngagement(true);

    try {
      const qtdAnswers = handleTotalInteractions();
      const question = await ProjectsService.getFilterQuestion(projectId);
      let questionNumber = null;
      let arrayMultiple = chatbotTemp.questions
        .filter((element) => {
          if (element.type === "multipleChoice") {
            return element;
          } else {
            return null;
          }
        })
        .map((element) => {
          return element.number < 10
            ? `q_0${element.number}`
            : `q_${element.number}`;
        });

      if (question) {
        questionNumber = getNumberQuestionInDictionary(dataFirebase, question);
      }

      if (dates && dateMin && !dateMax.length) {
        setDateMax(currentDate);
      }

      const jsonEngagement = JSON.stringify({
        userEmail: userInformation.email,
        campaign: projectId,
        qtdAnswers: typeof qtdAnswers === "number" ? qtdAnswers : 1000000,
        ...(dates && dateMin && { dateMin }),
        ...(dates && dateMin && !dateMax.length
          ? { dateMax: currentDate }
          : dateMax && { dateMax }),
        ...(question && { filterQuestion: questionNumber }),
      });

      const jsonReportData = JSON.stringify({
        project: projectId,
        numberQuestions: dataFirebase.questions.length,
        multiple: arrayMultiple,
        qtdAnswers: typeof qtdAnswers === "number" ? qtdAnswers : 1000000,
        user: userInformation.email,
        ...(dates && dateMin && { dateMin }),
        ...(dates && dateMin && !dateMax.length
          ? { dateMax: currentDate }
          : dateMax && { dateMax }),
      });

      let dataEngagement = await ProjectsService.getEngagement(jsonEngagement);
      setEngagementData(dataEngagement);

      let dataReport = await ProjectsService.getReportData(jsonReportData);
      setReportData({ intro: dataFirebase.disclaimer_initial, ...dataReport });

      let lastQuestion = dataFirebase.questions.slice(-1)[0].number;
      let avgAnswer = await ProjectsService.getAvgTimeAnswer(
        projectId,
        userInformation.email,
        lastQuestion < 10 ? `q_0${lastQuestion}` : `q_${lastQuestion}`,
      );
      setAnswerAvgTime(avgAnswer.avg);
    } finally {
      setLoadingEngagement(false);
    }
  };

  const getQuestionNumber = (index) => {
    return dataFirebase.questions[index].number < 10
      ? `q_0${dataFirebase.questions[index].number}`
      : `q_${dataFirebase.questions[index].number}`;
  };

  const showData = () => {
    switch (tabNavigation) {
      case "report":
        return (
          <div className="container_message">
            <div className="content">
              <div className="projectInformations">
                <img alt="hazo" src={Hazo} style={{ width: "60px" }} />
                <div className="informations">
                  <div className="mainText">
                    {engagementData?.answers.day.length > 0
                      ? parseInt(answerAvgTime) > 60
                        ? `${Math.round(parseInt(answerAvgTime) / 60)} min`
                        : `${parseInt(answerAvgTime)} seg`
                      : "0"}{" "}
                  </div>
                  <div className="secondaryText">Tempo médio de resposta</div>
                </div>
                <div
                  className={
                    searchStatus
                      ? "informationsStatus"
                      : "informationsStatus informationsGray"
                  }
                >
                  <div className="areaCloseProject">
                    <div className="areaButtonArea">
                      <ButtonOnOff
                        on={searchStatus}
                        handleValue={handleSearchIsOpen}
                      />
                    </div>
                    <div>
                      <p className="primaryText">
                        Status: {searchStatus ? "Aberta" : "Fechada"}
                      </p>
                      {searchStatus ? (
                        <p className="secondaryText">Aceitando respostas</p>
                      ) : (
                        <p className="secondaryText">Não aceitando respostas</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="filterArea">
                <img src={Calendar} alt="calendario" />
                <div className="inputDateArea">
                  De
                  <input
                    id="date"
                    value={dateMin}
                    name="date"
                    max={currentDate}
                    onChange={(evt) => {
                      setDateMin(evt.target.value);
                      setDateMax("");
                    }}
                    type="date"
                  />
                </div>
                <div className="inputDateArea">
                  Até
                  <input
                    id="date"
                    value={dateMax}
                    name="date"
                    min={dateMin}
                    onChange={(evt) => setDateMax(evt.target.value)}
                    type="date"
                    disabled={!dateMin.length}
                  />
                </div>
                <button
                  className="buttonBlue"
                  onClick={() =>
                    (dateMin.length || dateMax.length) && getDataWithDate()
                  }
                >
                  Filtrar
                </button>
                <button className="buttonGray" onClick={() => clearDates()}>
                  Limpar
                </button>
              </div>
              <div className="areaReports">
                <ChartsAndTables
                  changeKeysToEmojis={changeKeysToEmojis}
                  dataFirebase={dataFirebase}
                  dateMin={dateMin}
                  dateMax={dateMax}
                  handleShowTable={handleShowTable}
                  projectId={projectId}
                  reportData={reportData}
                  engagementData={engagementData}
                  setEngagementData={setEngagementData}
                  showTables={showTables}
                  setTypeOfChart={setTypeOfChart}
                  selectedQuestion={selectedQuestion}
                  setSelectedQuestion={setSelectedQuestion}
                  typeOfChart={typeOfChart}
                />
              </div>
            </div>
          </div>
        );
      case "database":
        return <ReportData getDatabaseToXlsx={getDatabaseToXlsx} />;
      default:
        break;
    }
  };

  const handleSearchIsOpen = () => {
    ProjectsService.setClosingProject(
      projectId,
      userInformation.email,
      !searchStatus ? 0 : 1,
      userInformation.subscription,
    );
    setSearchStatus(!searchStatus);
  };

  const handleShowTable = (questionNumber) => {
    setShowTables({
      [questionNumber]: !showTables[questionNumber],
    });
  };

  return (
    <div className="compReports" id="compReports" ref={pdfRef}>
      <div style={{ display: "none" }}>
        <table id="tableDatabase">{tableDatabaseToExcel}</table>
        <table id="tableConsolidated">
          <tbody id="tableConsolidatedbody">{dataTableXlsx}</tbody>
        </table>
        <table id="tableDatabase">{tableDatabaseToExcel}</table>
      </div>
      <ModalExport
        createPdf={handlePrint}
        createXlsxDatabase={createXlsxDatabase}
        createXlsxTable={createXlsxTable}
        modal={modalExport}
        setModal={setModalExport}
      />
      <HeaderResults />
      <NavigationHeader page="report" projectId={projectId} />
      <MenuReports
        modalExport={setModalExport}
        createXlsxDatabase={createXlsxDatabase}
        projectId={projectId}
        existAnswers={reportData?.answers.day.length > 0 ? true : false}
        setTabNavigation={setTabNavigation}
        tabNavigation={tabNavigation}
      />
      {loading ? <Loading /> : showData()}
    </div>
  );
};

export default Reports;
