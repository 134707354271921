import React from "react";
import Emoji1 from "../../../Assets/img/emoji-1.svg";
import Emoji2 from "../../../Assets/img/emoji-2.svg";
import Emoji3 from "../../../Assets/img/emoji-3.svg";
import Emoji4 from "../../../Assets/img/emoji-4.svg";
import Emoji5 from "../../../Assets/img/emoji-5.svg";
import GradientIcon from "../../../Assets/img/gradient.png";
import Star from "../../../Assets/img/star.svg";

const CondicionalsColor = (props) => {
  const handleBackgroundHexColor = (hexcolor) => {
    const r = parseInt(hexcolor.substr(1, 2), 16);
    const g = parseInt(hexcolor.substr(3, 2), 16);
    const b = parseInt(hexcolor.substr(5, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    const textColor = yiq >= 128 ? "black" : "white";

    props.handleColor(props.questionNumber, props.index, hexcolor, textColor);
  };

  const returnImage = (value) => {
    if (value.match(/^emoji-[0-5]{1}$/)) {
      switch (value) {
        case "emoji-1":
          return <img className="emojiIcon" alt="Emoji1" src={Emoji1} />;
        case "emoji-2":
          return <img className="emojiIcon" alt="Emoji2" src={Emoji2} />;
        case "emoji-3":
          return <img className="emojiIcon" alt="Emoji3" src={Emoji3} />;
        case "emoji-4":
          return <img className="emojiIcon" alt="Emoji4" src={Emoji4} />;
        case "emoji-5":
          return <img className="emojiIcon" alt="Emoji5" src={Emoji5} />;
        default:
          return value;
      }
    } else if (value.match(/^star-[0-5]{1}$/)) {
      const qtdStars = value.split("-")[1] * 1;
      let stars = [];
      for (let i = 1; i < qtdStars + 1; i++) {
        stars.push(<img alt="Star" key={i} src={Star} />);
      }

      return stars;
    } else {
      return value;
    }
  };

  return (
    <div key={props.index} className="options">
      {props.isNumerical && (
        <div
          className="editOnlyColor"
          onClick={() => props.handleClickInput("inputColor" + props.index)}
        >
          <img src={GradientIcon} alt="icon gradiente color" />
          <input
            id={"inputColor" + props.index}
            onChange={(e) => handleBackgroundHexColor(e.target.value)}
            type="color"
            value={props.option.color}
          />
        </div>
      )}
      <div
        className="option"
        style={{
          backgroundColor: props.option.color,
          color:
            props.option.textColor !== undefined
              ? props.option.textColor
              : "white",
        }}
      >
        {returnImage(props.option.value)}
      </div>
      <div className="gap">
        ir para
        <select
          className="dropdownSelectQuestion irParaMargin"
          value={
            props.option.next === 0 ? "Próxima Pergunta" : props.option.next
          }
          onChange={(event) =>
            props.handleNextOptions(
              props.questionNumber,
              props.index,
              event.target.value,
            )
          }
        >
          <option>Próxima Pergunta</option>
          {props.questionValues.questions.map((question, index) => {
            return (
              <option key={question.number} value={question.number}>
                Pergunta {index + 1}
              </option>
            );
          })}
          <option value="99999">Despedida</option>
        </select>
      </div>
    </div>
  );
};

export default CondicionalsColor;
