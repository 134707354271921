import React, { useContext, useEffect, useState } from "react";
import "../style.scss";
import "./style.scss";
import { Context } from "../../../Context/Context";
import { Tooltip } from "reactstrap";
import EditQuestionMenu from "../../EditQuestionMenu";
import JsonDictionaryService from "../../../Services/JsonDictionaryService";
import Pencil from "../../../Assets/img/edit.svg";
import Plus from "../../../Assets/img/plus.png";
import PlusBlack from "../../../Assets/img/plus-black.png";

const DisclaimerInitial = (props) => {
  const { chatbot, setChatbot } = useContext(Context);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipMessage, seTooltipMessage] = useState("");

  const handleCancel = () => {
    if (chatbot.disclaimer_initial.value !== "") {
      props.setQuestionValues({
        ...chatbot,
        disclaimer_initial: {
          ...chatbot.disclaimer_initial,
          edit: 0,
          error: false,
        },
      });
    }
  };

  const handleSave = async () => {
    let errorExists = props.validQuestions();
    if (!errorExists) {
      setChatbot({
        ...chatbot,
        disclaimer_initial: {
          edit: 0,
          value: props.questionValues.disclaimer_initial.value,
          error: false,
        },
      });
      props.setQuestionValues({
        ...props.questionValues,
        disclaimer_initial: {
          ...props.questionValues.disclaimer_initial,
          edit: 0,
          error: false,
        },
      });
      let projectJson = JSON.stringify({
        ...props.questionValues,
        disclaimer_initial: {
          ...props.questionValues.disclaimer_initial,
          edit: 0,
          error: false,
        },
      });
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        props.projectId,
        projectJson,
      );
    }
  };

  const handleSaveAndNew = async () => {
    let errorExists = props.validQuestions();
    if (!errorExists) {
      let arrayTemp = [...props.questionValues.questions];
      arrayTemp[props.questionNumber] = {
        ...arrayTemp[props.questionNumber],
        edit: 0,
      };
      setChatbot({
        ...chatbot,
        questions: arrayTemp,
        disclaimer_initial: {
          ...props.questionValues.disclaimer_initial,
          edit: 0,
          error: false,
        },
      });
      props.setQuestionValues({
        ...props.questionValues,
        questions: [
          {
            message: "",
            number: parseInt(props.questionValues.questions.length) + 1,
            options: [],
            type: "",
            edit: 1,
          },
          ...arrayTemp,
        ],
        disclaimer_initial: {
          ...props.questionValues.disclaimer_initial,
          edit: 0,
          error: false,
        },
      });
      let projectJson = JSON.stringify({
        ...props.questionValues,
        disclaimer_initial: {
          ...props.questionValues.disclaimer_initial,
          edit: 0,
          error: false,
        },
      });
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        props.projectId,
        projectJson,
      );
    }
  };

  const handleEdit = () => {
    let errorExists = props.validQuestions();
    if (!errorExists) {
      props.setQuestionValues({
        ...props.questionValues,
        disclaimer_initial: {
          ...props.questionValues.disclaimer_initial,
          edit: 1,
        },
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e !== null) {
      if (e.target !== null) {
        e.target.style.height = `${e.target.scrollHeight}px`;
      }
    }
  };

  useEffect(() => {
    const validError = () => {
      if (props.questionValues.disclaimer_initial.value === "") {
        seTooltipMessage("Digite uma mensagem de boas vindas!");
        setTooltipOpen(true);
      }
    };

    if (props.questionValues.disclaimer_initial.error) {
      validError();
    } else {
      setTooltipOpen(false);
    }
    // eslint-disable-next-line
  }, [props.questionValues]);

  if (!props.questionValues.disclaimer_initial.edit) {
    return (
      <div className="compDisclaimerInitial boxNoEdit">
        <img
          alt="robot"
          className="robotIcon"
          src={`${process.env.REACT_APP_BASE_URL}dist/img/${props.questionValues.robot_image_name}`}
          style={{
            visibility: props.lastQuestion === 0 ? "visible" : "hidden",
          }}
        />
        <div className="questionArea">
          <div className="title">Boas Vindas</div>
          <div className="inputTitle">
            <p style={{ whiteSpace: "pre-line" }}>
              {props.questionValues.disclaimer_initial.value}
            </p>
          </div>
          <div className="editAreaDI">
            <div onClick={() => handleEdit()}>
              <img alt="botao de edit" src={Pencil} />
            </div>
            {props.questionValues.questions.length > 0 ? null : (
              <button onClick={() => props.addQuestion("welcome")}>
                <img alt="new" src={Plus} />
              </button>
            )}
            {props.questionValues.disclaimer_initial.edit === 0 &&
              props.questionValues.questions.length > 0 && (
                <button onClick={() => props.addQuestion("welcome")}>
                  <img
                    alt="new"
                    src={PlusBlack}
                    style={{
                      background: "#c4c4c4",
                      borderRadius: "5px",
                    }}
                  />
                </button>
              )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="compDisclaimerInitial boxEdit">
        <img
          alt="robot"
          className="robotIcon"
          src={`${process.env.REACT_APP_BASE_URL}dist/img/${props.questionValues.robot_image_name}`}
          style={{
            visibility: props.lastQuestion === 0 ? "visible" : "hidden",
          }}
        />
        <div className="questionArea">
          <div className="title">Boas Vindas</div>
          <div
            id="DisclaimerInicial"
            className={
              !tooltipOpen ? "inputTitle" : "inputTitle inputTitleError"
            }
          >
            <Tooltip
              innerClassName="redBackground"
              className="redBackground"
              placement="top"
              popperClassName="tooltipZero"
              isOpen={tooltipOpen}
              target={"DisclaimerInicial"}
            >
              {tooltipMessage}
            </Tooltip>
            <textarea
              rows="3"
              className="form-control"
              value={props.questionValues.disclaimer_initial.value}
              placeholder="Digite aqui uma introdução para o seu projeto, explicando o seu objetivo e inspirando as pessoas a responder!"
              onChange={(event) => {
                setTooltipOpen(false);
                props.setQuestionValues({
                  ...props.questionValues,
                  disclaimer_initial: {
                    ...props.questionValues.disclaimer_initial,
                    value: event.target.value,
                    error: false,
                  },
                });
                handleKeyDown(event);
              }}
            />
          </div>
          <EditQuestionMenu
            handleCancel={handleCancel}
            handleSave={handleSave}
            handleSaveAndNew={handleSaveAndNew}
            questionNumber={props.questionNumber}
            showSavePlusNew={
              props.questionValues.questions.length === 0 ? true : false
            }
          />
        </div>
      </div>
    );
  }
};

export default DisclaimerInitial;
