import React from "react";
import DownArrow from "../../../Assets/img/down-arrow.svg";
import GradientIcon from "../../../Assets/img/gradient.png";
import Trash from "../../../Assets/img/trash_2.png";
import UpArrow from "../../../Assets/img/up-arrow.svg";

const InputColor = (props) => {
  const LENGTH_CHARACTERES_BOX_TEXTAREA = 23;
  let rows;

  const handleBackgroundHexColor = (hexcolor) => {
    const r = parseInt(hexcolor.substr(1, 2), 16);
    const g = parseInt(hexcolor.substr(3, 2), 16);
    const b = parseInt(hexcolor.substr(5, 2), 16);

    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    const textColor = yiq >= 128 ? "black" : "white";

    props.handleColor(props.questionNumber, props.index, hexcolor, textColor);
  };

  const calculateNumberOfRowsOfTextarea = () => {
    const lengthCharacteresTextArea = props.option.value.length;

    if (lengthCharacteresTextArea > LENGTH_CHARACTERES_BOX_TEXTAREA) {
      if (lengthCharacteresTextArea % LENGTH_CHARACTERES_BOX_TEXTAREA === 0) {
        rows = lengthCharacteresTextArea / LENGTH_CHARACTERES_BOX_TEXTAREA;
      } else if (
        lengthCharacteresTextArea % LENGTH_CHARACTERES_BOX_TEXTAREA !==
        0
      ) {
        rows = lengthCharacteresTextArea / LENGTH_CHARACTERES_BOX_TEXTAREA + 1;
      } else if (lengthCharacteresTextArea < LENGTH_CHARACTERES_BOX_TEXTAREA) {
        rows = 1;
      }
    } else {
      rows = 1;
    }

    return rows;
  };

  return (
    <>
      <textarea
        className="optionsInput"
        placeholder="Digite a opção"
        rows={calculateNumberOfRowsOfTextarea()}
        value={props.option.value}
        style={{
          backgroundColor: props.option.color,
          color:
            props.option.textColor !== undefined
              ? props.option.textColor
              : "white",
        }}
        onChange={(event) => {
          props.setTooltipOpen(false);
          props.handleKeyDown(event);
          props.handleOptionValue(
            props.questionNumber,
            props.index,
            event.target.value,
          );
        }}
      />
      <div
        className="editOnlyColor"
        onClick={() => props.handleClickInput("inputColor" + props.index)}
      >
        <img src={GradientIcon} alt="icon gradiente color" />
        <input
          id={"inputColor" + props.index}
          onChange={(e) => handleBackgroundHexColor(e.target.value)}
          type="color"
          value={props.option.color}
        />
      </div>
      <div className="editGroup">
        <img
          src={Trash}
          alt="trash icon"
          onClick={() =>
            props.handleRemoveOption(props.questionNumber, props.index)
          }
        />
      </div>
      <div className="editGroup">
        <img
          src={DownArrow}
          alt="down arrow"
          onClick={() =>
            props.moveDownOption(props.questionNumber, props.index)
          }
        />
      </div>
      <div className="editGroup">
        <img
          src={UpArrow}
          alt="down arrow"
          onClick={() => props.moveUpOption(props.questionNumber, props.index)}
        />
      </div>
    </>
  );
};

export default InputColor;
