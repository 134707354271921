import { firebaseAuth } from "../Config/firebase.js";
import firebase from "firebase/app";
import Axios from "axios";
export const Auth = firebaseAuth;
require("dotenv").config();
export default class AuthService {
  //Serviço de cadastro
  static signUp = async (dataSignUp) => {
    const db = firebase.firestore();
    let status = {};

    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API}new_user/`,
      async: true,
      data: JSON.stringify({
        email: dataSignUp.email,
      }),
    })
      .then(async () => {
        await firebaseAuth
          .createUserWithEmailAndPassword(dataSignUp.email, dataSignUp.password)
          .then(async (resp) => {
            let user_uid = resp.user.uid;
            db.collection("users").doc(user_uid).set({
              name: dataSignUp.name,
              email: dataSignUp.email.toLowerCase(),
              phone: dataSignUp.phone,
              projects: [],
              subscription: "Free 1.0",
            });
            await firebaseAuth.signInWithEmailAndPassword(
              dataSignUp.email,
              dataSignUp.password,
            );
          })
          .catch((e) => {
            status = {
              type: e.code,
            };
          });
        status = {
          message: "Conta cadastrada com sucesso!",
          type: "success",
        };
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          status = {
            message: "Este e-mail já está cadastrado",
            type: "error",
          };
        }
      });

    return status;
  };

  static signIn = async (email, password) => {
    let emailAjusted = email;
    return await firebaseAuth.signInWithEmailAndPassword(
      emailAjusted,
      password,
    );
  };

  static signOut = () => {
    firebaseAuth.signOut().then(() => {
      localStorage.removeItem("@hazoapp");
    });
  };

  static reAuth = async (password, newPassword) => {
    const user = firebaseAuth.currentUser;

    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password,
    );

    let status = {};

    await user
      .reauthenticateWithCredential(credential)
      .then(async () => {
        await user
          .updatePassword(newPassword)
          .then(async () => {
            status = {
              message: "Senha alterada com sucesso!",
              type: "success",
            };
          })
          .catch(() => {
            status = {
              message: "Não foi possível alterar a senha!",
              type: "fail",
            };
          });
      })
      .catch(() => {
        status = {
          message: "Senha atual incorreta!",
          type: "fail",
        };
      });

    return status;
  };

  static resetPassword = async (email) => {
    let status = {};

    if (
      email.match(
        // eslint-disable-next-line
        /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
      )
    ) {
      await firebaseAuth
        .sendPasswordResetEmail(email)
        .then(() => {
          status = {
            message: "Resent",
            code: "success",
          };
        })
        .catch((error) => {
          status = {
            message: error.message,
            code: error.code,
          };
        });
    }
    return status;
  };

  static verifyRecaptcha = async (token) => {
    let response = null;
    let dataToSend = {
      recaptchaToken: token,
    };

    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}v2/verify_recaptcha/`,
      data: JSON.stringify(dataToSend),
    }).then((res) => {
      response = res;
    });

    return response;
  };
}
