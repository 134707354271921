import React, { useState } from "react";
import "./style.scss";
import { Modal, ModalHeader } from "reactstrap";

const FilterContainer = ({
  setModalFilter,
  modalFilter,
  data,
  setSelectedOptions,
}) => {
  const [selected, setSelected] = useState([]);

  const handleSave = () => {
    setModalFilter(!modalFilter);
  };

  const getSelectedOptions = () => {
    let filterList = [];
    data.options?.filter((opt, i) =>
      opt.checked === true
        ? filterList.push({ index: i, value: opt.value })
        : [],
    );
    return filterList;
  };

  const handleAllChecked = () => {
    let updatedList = getSelectedOptions();
    data.options.forEach((obj, i) => {
      const object = { index: i, value: obj.value };
      updatedList.push(object);
    });

    setSelected(updatedList);
    setSelectedOptions(updatedList);
  };

  const handleCleanAll = () => {
    let updatedList = [];
    data.options.forEach((obj, i) => {
      const object = { index: i, value: obj.value };
      updatedList.push(object);
    });
    setSelected(data.options);
    setSelectedOptions([]);
  };

  const handleCheck = (event, i) => {
    let updatedList = getSelectedOptions();
    if (event.target.checked) {
      updatedList = [...selected, { index: i, value: event.target.value }];
    } else {
      updatedList = updatedList.filter((x) => x.index !== i);
    }
    setSelected(updatedList);
    setSelectedOptions(updatedList);
  };

  const toggle = () => {
    setModalFilter(!modalFilter);
  };

  if (Array.isArray(data.options) && data.options?.length > 0) {
    return (
      <Modal
        className="compModalCharts"
        isOpen={modalFilter}
        centered={true}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}></ModalHeader>
        <span className="question-header">Pergunta {data.number}</span>
        <div className="modal-container">
          <section className="modal-section">
            <div className="filter-content">
              <div className="header-filter">
                <span className="filter-num">Filtro</span>
              </div>
              <div className="modal-content">
                <p>{data.message}</p>
                {data.options.map((el, i) => {
                  return (
                    <label className="label-options" key={i}>
                      <input
                        value={el.value}
                        checked={el.checked}
                        type="checkbox"
                        onChange={(event) => handleCheck(event, i)}
                      />
                      {el.value}
                    </label>
                  );
                })}
              </div>
            </div>
          </section>

          <div className="buttons-bottom">
            <button className="btn-save" onClick={() => handleAllChecked()}>
              Aplicar todos
            </button>
            <button className="btn-save" onClick={() => handleCleanAll()}>
              Limpar todos
            </button>
            <button className="btn-save" onClick={() => handleSave()}>
              Salvar
            </button>
          </div>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};

export default FilterContainer;
