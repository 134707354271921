import React from "react";
import Emoji1 from "../../../Assets/img/emoji-1.svg";
import Emoji2 from "../../../Assets/img/emoji-2.svg";
import Emoji3 from "../../../Assets/img/emoji-3.svg";
import Emoji4 from "../../../Assets/img/emoji-4.svg";
import Emoji5 from "../../../Assets/img/emoji-5.svg";
import InputColor from "../InputColor";

const EmojiScale = (props) => {
  return (
    <div className="optionsArea">
      <div className="options">
        Opção 1:
        <img alt="Emoji1" src={Emoji1} />
      </div>
      <div className="options">
        Opção 2:
        <img alt="Emoji2" src={Emoji2} />
      </div>
      <div className="options">
        Opção 3:
        <img alt="Emoji3" src={Emoji3} />
      </div>
      <div className="options">
        Opção 4:
        <img alt="Emoji4" src={Emoji4} />
      </div>
      <div className="options">
        Opção 5:
        <img alt="Emoji5" src={Emoji5} />
      </div>
      {props.questionValues.questions[props.questionNumber].options.map(
        (option, index) => {
          if (index > 4) {
            return (
              <div key={index} className="options">
                <div>Opção {index + 1}:</div>
                <InputColor
                  option={option}
                  index={index}
                  questionNumber={props.questionNumber}
                  handleColor={props.handleColor}
                  setTooltipOpen={props.setTooltipOpen}
                  handleOptionValue={props.handleOptionValue}
                  handleClickInput={props.handleClickInput}
                  handleKeyDown={props.handleKeyDown}
                  handleRemoveOption={props.handleRemoveOption}
                  moveDownOption={props.moveDownOption}
                  moveUpOption={props.moveUpOption}
                />
              </div>
            );
          }
          return null;
        },
      )}
      <button
        className="buttonAddOption"
        onClick={() => props.handleAddOption(props.questionNumber)}
      >
        Adicionar Opção
      </button>
    </div>
  );
};

export default EmojiScale;
