import BarChart from "./BarChart";
import ChartDataLabels from "chartjs-plugin-datalabels";
import NpsChart from "./NpsChart";
import PieChart from "./PieChart";

const ChartsToReport = ({ data, chartType, question }) => {
  let dataToChart = [];

  switch (question.type) {
    case "openAnswerCellPhone":
    case "openAnswerCPF":
    case "openAnswerDate":
    case "openAnswerEmail":
    case "openAnswerFreeText":
    case "openAnswerHour":
    case "openAnswerOnlyNumbers":
    case "openAnswerPhone":
      dataToChart = data.slice(0, 15);

      if (dataToChart.length > 15) {
        let percentOfDataToChart = 0;
        let qtdOfDataToChart = 0;
        data.slice(15).map((element) => {
          percentOfDataToChart += Number(element.value);
          qtdOfDataToChart += Number(element.qtd);
          return element;
        });

        dataToChart.push({
          option: "Outros",
          qtd: qtdOfDataToChart,
          value: percentOfDataToChart.toFixed(1),
        });
      }
      break;
    default:
      dataToChart = data.map((element) => element);
      break;
  }

  const labelsAdjusted = dataToChart.map((element) => {
    if (element.option.length > 25) {
      const newArr = element.option.slice(0, 25).split(" ");
      return [
        (newArr.slice(0, 2) + " ").replace(",", " "),
        (newArr.slice(2, 4) + "...").replace(",", " "),
      ];
    }
    return element.option;
  });

  const userData = {
    labels: labelsAdjusted,
    datasets: [
      {
        label: "",
        data: dataToChart.map((element) => element.value),
        backgroundColor: `#609ef1`,
        borderWidth: 1,
      },
    ],
  };

  switch (chartType) {
    case "PieChart":
      return <PieChart data={userData} plugins={[ChartDataLabels]} />;
    case "BarChart":
    case "ColumnChart":
      return (
        <BarChart
          data={userData}
          plugins={[ChartDataLabels]}
          chartType={chartType}
        />
      );
    case "NPS":
      return <NpsChart data={data} />;
    default:
      return (
        <BarChart
          data={userData}
          plugins={[ChartDataLabels]}
          chartType={"BarChart"}
        />
      );
  }
};

export default ChartsToReport;
